import { useLanguage, useLocaleContext } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import axios from 'lib/axios'
import { appUtils } from 'lib/AppUtils'
import Head from 'next/head'
import { UserCartButton } from '@/components/Cart'
import { Categories } from '@/components/Layouts/Category'
import { useEffect, useState } from 'react'
import { Category } from '@models/Index'
import { useRouter } from 'next/router'

export default function Home() {
  const { navigation: navStr } = useLanguage()
  const [data, setData] = useState<Category[]>([])
  const router = useRouter()
  const { locale } = useLocaleContext()
  useEffect(() => {
    if (!router.isReady || !locale) return
    const fetchCategories = async () => {
      await axios
        .get(API_URL.category + '?store_id=2&source=h', {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': locale,
          },
          withCredentials: true,
        })
        .then(res => {
          if (res && res.data && res.data.data) {
            setData(res.data.data)
          }
        })
    }

    fetchCategories()
  }, [router.isReady, locale])

  return (
    <div className='flex flex-col'>
      <Head>
        <title>{navStr.app_name}</title>
        {/* <meta name='description' content={navStr.meta.description2} key='description' /> */}
        <meta property='og:title' content={navStr.competitions + ' - ' + navStr.app_name} key='og:title' />
        {/* <meta name='og:description' content={navStr.meta.description2} key='og:description' /> */}
        <meta property='og:type' content='article' key='og:type' />
        <meta name='twitter:title' content={navStr.competitions + ' - ' + navStr.app_name} key='twitter:title' />
        {/* <meta name='twitter:description' content={navStr.meta.description2} key='twitter:description' /> */}
        <link rel='icon' href='/assets/favicons/favicon.ico' />
      </Head>

      <div className='main-container'>
        <div className='relative mb-4 w-full'>
          <UserCartButton />
          <div className='flex w-full flex-col'>{data && !appUtils.isEmpty(data) && <Categories categoryList={data} />}</div>
        </div>
      </div>
    </div>
  )
}
