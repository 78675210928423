import { Category } from '@models/Index'
import router from 'next/router'
import { Fragment, FC } from 'react'
import { CompetitionList } from './Competition'

export function CategoryHeader(props: { name: string; viewAllText?: string; viewAllAction?: () => void }) {
  return (
    <div className='mb-2 flex items-center justify-between'>
      <span className='text-lg font-semibold md:text-xl'>{props.name}</span>
    </div>
  )
}
interface CategoriesProps {
  categoryList: Category[]
}

export const Categories: FC<CategoriesProps> = ({ categoryList }) => {
  return (
    <Fragment>
      {categoryList.map((category, index) => {
        return (
          <div key={index} id={`category-${index}`} className={`flex w-full animate-fade-in-up flex-col ${index == 0 ? 'mt-2 md:mt-4' : 'mt-6'}`}>
            <CategoryHeader
              name={category.name}
              viewAllAction={() => {
                router.push('/competitions#category-' + index, null, { shallow: true })
              }}
            />

            <CompetitionList competitions={category.competitions} />
          </div>
        )
      })}
    </Fragment>
  )
}
